var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('section',{staticClass:"carousel-section"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"carouselExampleIndicators","data-ride":"carousel","data-interval":"3000"}},[_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('router-link',{attrs:{"to":"/join"}},[_c('img',{staticClass:"d-block",attrs:{"src":"/img/carousel/carousel.webp"}})])],1),_c('div',{staticClass:"carousel-item"},[_c('router-link',{attrs:{"to":"/deposit"}},[_c('img',{staticClass:"d-block",attrs:{"src":"/img/carousel/carousel.webp"}})])],1),_c('div',{staticClass:"carousel-item"},[_c('router-link',{attrs:{"to":{
              name: _vm.aviatorCrashGameUrl,
              params: {
                IsDemo: 0,
                providerID: _vm.aviatorProviderID,
                gameName: _vm.aviatorGameName,
                gameID: _vm.aviatorGameID,
              },
            }}},[_c('img',{staticClass:"d-block",attrs:{"src":"/img/carousel/carousel.webp"}})])],1),_c('div',{staticClass:"carousel-item"},[_c('router-link',{attrs:{"to":{
              name: _vm.jetxCrashGameUrl,
              params: {
                IsDemo: 0,
                providerID: _vm.jetxProviderID,
                gameName: _vm.jetxGameName,
                gameID: _vm.jetxGameID,
              },
            }}},[_c('img',{staticClass:"d-block",attrs:{"src":"/img/carousel/carousel.webp"}})])],1)]),_vm._m(0),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"carousel-control-prev",attrs:{"href":"#carouselExampleIndicators","role":"button","data-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Previous")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"carousel-control-next",attrs:{"href":"#carouselExampleIndicators","role":"button","data-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Next")])])
}]

export { render, staticRenderFns }