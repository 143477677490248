<template>

  <div v-show="current_sub_page !== 'countries'" style="margin-left: 2%;">

    <h4 class="h2-trend" style="color: #40a3ff;">Trending Leagues</h4>

    <div class="h3-trend" style="color: #fcbf10;">Leagues</div>

    <div style="background-color: #171b3e; width: 98%;">

      <div v-for="(l,index) in leagues" v-bind:key="index" style="color: var(--black);" @click="filterByLeague(l.competition_id)">
        <router-link
        :to="{name: '', params: {sport_id: l.sport_id, competition_id: l.competition_id}}">
          <div class="trend-item">

          <div class="d-flex">
            <div class="trend-text1">
              <span class="bold" v-text="l.competition"></span> - <span v-text="l.category_name"></span>
            </div>
          </div>

          <div class="trend-text1">{{ l.games }}
            <span style="position: relative;top: -1px;">
                  <lazy-image style="height: 1em;" class="lozad" v-bind:src="getURL('/assets/icons/right.svg')" />
                </span>
          </div>

          metaInfo: {
            title: 'Choplife Promotions - Exciting Bonuses and Offers',
            meta: [
              { name: 'description', content: 'Discover the latest promotions and bonuses on Choplife Get free bets, cashback, and more. Don\'t miss out!' },
              {
                name: 'keywords', content: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting,' +
                  'Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In- play betting, Cash out, Live odds, Betting tips, Aviator,' +
                  'Jetx, Cometcrash, Spin2wiin, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support,' +
                  'Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score'
              }
            ]
          },
        </div>
        </router-link>

        <div class="" style="background-color: var(--gray); height: .1em;"></div>

      </div>

      <div class="" style="background-color: var(--gray); height: .1em;"></div>
    </div>

  </div>

</template>

<script>
export default {
  name: "Trending",
  methods: {

    filterByLeague: function (competition_id) {

      // set competitionID and get games
      this.$store.dispatch("setCompetitionID",competition_id);
    },
    filterByCountry: function (country_code) {

      // reset filters
      var default_payload = this.$store.state.default_games_filter;

      // set country code
      default_payload.country_code = country_code;
      console.log('filterByCountry ==> '+JSON.stringify(default_payload));

      // get games
      this.$store.dispatch("setGamesFilter",default_payload);

    },
    showCountries: function(){

      this.sub_page = "countries";
      this.$store.dispatch("setCurrentSubPage","countries");

    }

  },
  computed: {

    leagues: function () {

      var dt = this.$store.state.leagues;

      var f = [];
      var n = 0;
      var max = dt === null || dt === undefined ? 0 : dt.length;
      var limit = 5;

      while (n < max ) {

        f.push(dt[n]);

        n++;

        if(f.length > limit ) {

          break;
        }
      }

      return f;
    },
    countries: function () {

      var dt = this.$store.state.countries;
      var f = [];
      var n = 0;
      var max = dt === null || dt === undefined ? 0 : dt.length;
      var limit = 5;

      if(this.current_sub_page === 'countries') {

        limit = dt.length;

      }

      while (n < max ) {

        f.push(dt[n]);

        n++;

        if(f.length > limit ) {

          break;
        }
      }

      return f;

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    }
  }
}
</script>

<style scoped>
.trend-text1{
  text-decoration: none !important;
}

</style>