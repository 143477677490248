<template>

  <section class="text-center main-footer">

    <p style="font-size: var(--font-largest);font-weight: bold; margin-bottom: 0.4em;">Omoka<span style="color: #fbeb34;">BET</span></p>


    <p style="margin: 0;">
      <a href="tel:0712895599">
        <button class="btn btn-sm btn-round">
          <span><img style="height: 1.5em; font-weight: bold;" src="https://d8060roj23kfv.cloudfront.net/assets/icons/phone.svg"></span> <span class="white-txt" style="font-size: 13.6px; font-weight: bold">0712895599</span>
        </button>
      </a>,

      <a href="tel:0701895544">
        <button class="btn btn-sm btn-round">
          <span><img style="height: 1.5em; font-weight: bold;" src="https://d8060roj23kfv.cloudfront.net/assets/icons/phone.svg"></span> <span class="white-txt" style="font-size: 13.6px; font-weight: bold">0701895544</span>
        </button>
      </a>
    </p>

    <div class="footer-social mb-3" style="margin-bottom:  0.5em !important;">
      <a href="https://www.facebook.com/chopLifecom?mibextid=LQQJ4d" target="_blank" class="btn btn-fab btn-round btn-facebook">
        <img class="social-btn" src="https://d8060roj23kfv.cloudfront.net/assets/icons/facebook.svg">
      </a>

      <a href="https://x.com/chopLife_kenya?s=21&t=_ZmI-6RKcSSVGyAF5abuDg" target="_blank" class="btn btn-fab btn-round btn-twitter">
        <img class="social-btn" src="https://d8060roj23kfv.cloudfront.net/assets/icons/twitter.svg">
      </a>

      <a href="https://www.instagram.com/chopLife_kenya?igsh=OGd6MjJqMXFqaWJ6" target="_blank" class="btn btn-fab btn-round btn-instagram">
        <img class="social-btn" src="https://d8060roj23kfv.cloudfront.net/assets/icons/instagram.svg">
      </a>
    </div>

    <p>
      <img style=" height: 29px;margin-top: .3em;" src="https://d8060roj23kfv.cloudfront.net/assets/icons/age-limit.svg">
    </p>

    <ul class="footer-menu">
    </ul>

    <div>
      <p class="legal-text" style="font-size: var(--font-medium);line-height: 1.2em;">
        Choplife Limited trading as Choplife is Licenced by the Betting Control & Licensing Board of Kenya. License 0000324
      </p>
    </div>

  </section>

</template>

<script>

export default {
  name: "BottomFooter",
}

</script>